import React, { useState, useEffect } from 'react'
import { Collapse, Navbar, NavbarToggler, NavbarBrand } from 'reactstrap'
import { FaBars } from 'react-icons/fa'
import { StaticImage } from 'gatsby-plugin-image'

import NavbarLinks from './NavbarLinks'
import NavbarLinksContacts from './NavbarLinksContacts'

const NavbarSection = () => {
  // Used for reactstrap Navbar
  const [open, setOpen] = useState(false)
  const toggleNavbar = () => setOpen(!open)

  // Clicking the brand logo
  function scrollToTop(e) {
    e.preventDefault()
    setOpen(false)
    window.scrollTo(0, 0)
  }

  // Animate the Navbar when
  const [scrolled, setScrolled] = useState(typeof window !== 'undefined' && window.scrollY)
  useEffect(() => {
    const logScroll = () => setScrolled(typeof window !== 'undefined' && window.scrollY)
    typeof window !== 'undefined' && window.addEventListener('scroll', logScroll)
    // Similar to componentWillUnmount
    return () => typeof window !== 'undefined' && window.removeEventListener('scroll', logScroll)
  })

  return (
    <Navbar fixed="top" expand="lg" id="mainNav" className={scrolled > 200 || open ? 'navbar-shrink' : 'p-1'}>
      <div className="container d-flex justify-between">
        <NavbarBrand href="/" onClick={scrollToTop} className="p-0">
          <StaticImage src="./img/logonew4.png" className="navbar-brand" alt="Aurasjobs Brand" layout="fullWidth" />
        </NavbarBrand>

        <NavbarToggler onClick={toggleNavbar} className="navbar-toggler p-2 mr-2">
          Menu <FaBars />
        </NavbarToggler>

        <Collapse isOpen={open} navbar>
          <NavbarLinks closeCollapseOnClick={() => setOpen(false)} />
          {/* Hide on large devices, will show in Header on large devices  */}
          <div className="d-block d-lg-none px-2">
            <NavbarLinksContacts />
          </div>
        </Collapse>
      </div>
    </Navbar>
  )
}

export default NavbarSection
