import React from 'react'
import { useTranslation } from 'react-i18next'
import { Nav, NavItem, NavLink } from 'reactstrap'

import ukFlag from './img/uk_flag_42x28.png'
import roFlag from './img/ro_flag_42x28.png'

import i18n from '../../i18n/i18n'

const NavbarLinks = ({ closeCollapseOnClick }) => {
  const { t } = useTranslation()

  const handleLanguageChange = (e) => {
    e.preventDefault()

    i18n.changeLanguage(i18n.language === 'ro' ? 'en' : 'ro')
  }

  return (
    <Nav className="navbar-nav ml-auto px-2">
      <NavItem className="nav-item rounded">
        <a className="nav-link" href="#about" onClick={closeCollapseOnClick}>
          {t('about')}
        </a>
      </NavItem>
      <NavItem className="nav-item">
        <a className="nav-link" href="#jobs" onClick={closeCollapseOnClick}>
          {t('jobs')}
        </a>
      </NavItem>
      <NavItem className="nav-item">
        <a className="nav-link" href="#journey" onClick={closeCollapseOnClick}>
          {t('journey')}
        </a>
      </NavItem>
      <NavItem className="nav-item">
        <a className="nav-link" href="#apply" onClick={closeCollapseOnClick}>
          {t('apply')}
        </a>
      </NavItem>
      <NavItem className="nav-item">
        <a className="nav-link" href="#contact" onClick={closeCollapseOnClick}>
          Contact
        </a>
      </NavItem>
      {/* <NavItem className="nav-item p-0">
        <NavLink className="nav-link flag pb-2" href="/" onClick={handleLanguageChange}>
          {i18n.language === 'ro' ? (
            <>
              {' '}
              <img src={ukFlag} width="36" height="22" alt="United Kingdom`s flag" /> EN{' '}
            </>
          ) : (
            <>
              {' '}
              <img src={roFlag} width="36" height="22" alt="Romanian flag" /> RO{' '}
            </>
          )}
        </NavLink>
      </NavItem> */}
    </Nav>
  )
}

export default NavbarLinks
