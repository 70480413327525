import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../../assets/scss/index.scss'

import NavbarSection from './NavbarSection'
import Footer from './Footer'

import http from '@utils/http'

import '../../i18n/i18n'

const Layout = ({ children }) => {
  useEffect(() => {
    const wakeUpBackend = async () => {
      try {
        await http.get('/')
      } catch (err) {}
    }

    window.addEventListener('mousemove', wakeUpBackend, { once: true })
    window.addEventListener('touchmove', wakeUpBackend, { once: true })

    return () => {
      window.removeEventListener('mousemove', wakeUpBackend)
      window.removeEventListener('touchmove', wakeUpBackend)
    }
  }, [])

  return (
    <>
      <NavbarSection />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default Layout
