import React, { useState } from 'react'
import { Modal, ModalFooter, ModalBody } from 'reactstrap'

import { FaTimes } from 'react-icons/fa'

const ContactModalSuccess = ({ phone, email }) => {
  const [modal, setModal] = useState(true)

  const toggle = (e) => {
    e.preventDefault()
    setModal(!modal)
  }

  return (
    <Modal isOpen={modal} toggle={toggle} centered={true}>
      <div className="py-4 px-2">
        <p className="text-center">Iti multumim pentru cererea de contact. Te rugam sa verifici detaliile: </p>
        <ModalBody className="list-group my-1">
          <li className="list-group-item font-weight-bold">Telefon: {phone}</li>
          <li className="list-group-item font-weight-bold">Email: {email}</li>
        </ModalBody>

        <ModalFooter className="border-top-0">
          <p>Daca nu te vom suna in urmatoarele zile te rugam sa ne contactezi telefonic.</p>
          <button type="button" className="btn btn-danger mx-auto" onClick={toggle}>
            <FaTimes /> Inchide
          </button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default ContactModalSuccess
